@tailwind base;
@tailwind components;
@tailwind utilities;

.nav-link {
  position: relative;
  padding-bottom: 6px;
}
.nav-link::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: white;
  bottom: -15px;
  left: 0;
  transform: scaleX(0);
  transform-origin: bottom;
  transition: transform 0.3s ease, bottom 0.3s ease;
}
.nav-link:hover::before {
  transform: scaleX(1);
  bottom: 0;
  transform-origin: bottom;
}
.nav-link.active::before {
  transform: scaleX(1);
  bottom: 0;
  background-color: white;
  transform-origin: bottom;
}
.logo {
  transition: filter 0.3s ease;
}
.logo:hover {
  filter: grayscale(70%); 
}
/* .logo-background {
  overflow: hidden;
}
.logo-background::before {
  content: '';
  position: absolute;
  top: 0;
  left: -20%;
  height: 100%;
  width: 140%;
  background: #929292;
  clip-path: polygon(0 0, 100% 0, 80% 100%, 0 100%);
  z-index: -1; 
} */